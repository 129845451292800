"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePublicQuestionsQuery = exports.useQuestionQuery = void 0;
const redux_state_1 = require("@alpha-prep/redux-state");
const shared_1 = require("../shared");
const baseApiNew = (0, shared_1.isProduction)() ? redux_state_1.baseApiProd : redux_state_1.baseApi;
const diagnosticApi = baseApiNew.injectEndpoints({
    endpoints: (builder) => ({
        question: builder.query({
            query: (id) => `/cms/question/${id}`,
        }),
        publicQuestions: builder.query({
            query: (id) => `/cms/question`,
        })
    }),
    overrideExisting: false,
});
exports.useQuestionQuery = diagnosticApi.useQuestionQuery, exports.usePublicQuestionsQuery = diagnosticApi.usePublicQuestionsQuery;
