import { Cookies } from 'react-cookie';
import { getApiUrl } from './apicall';

let cmsUrl: string | null = null;

const init = async () => {
	cmsUrl = await getCmsURL();
};

const getDomain = () => {
	try {
		let domain: string = window.location.hostname;
		const urlParts = domain.split('.');
		domain = urlParts
			.slice(0)
			.slice(-(2))
			.join('.');
		return { path: '/', domain: domain };
	} catch (error) {}
	return null;
};

const getDashboardDomain = () => {
    try {
        let domain: string = window.location.hostname;
        const urlParts = domain.split('.');
        const segmentsToKeep = urlParts.length > 2? 2 : 1;
        domain = urlParts.slice(-segmentsToKeep).join('.');
        return { path: '/', domain: domain };
    } catch (error) {}
    return null;
};

const getHostName = (withPrefix?: boolean) => {
	const loc = window.location;
	const mode: string = loc.hostname === 'localhost' ? 'development' : 'production';
	const urlParts = loc.host.split('.');
	const domain = getDomain();
	const isWww = urlParts.length > 0 && urlParts[0]?.toLowerCase() === 'www' ? true : false;
	const prodURL = isWww ? (withPrefix ? `www.${domain?.domain}` : domain?.domain) : `${loc.host}`;
	console.log('prod URL:', prodURL);
	const host = mode === 'development' ? 'localhost' : prodURL;
	return host;
};

const getMainUrl = async () => {
	try {
		const currentUrl = window.location.href;
		const isLocal = currentUrl.includes('localhost');
		const hostName: any = getHostName(true);
		const hostList = hostName?.split('.');
		const hostListNew = hostList.filter((item: string) => item !== 'dashboard');
		const host = hostListNew.join('.');
		const prodURL = `${window.location.protocol}//${host}`;
		const mainUrl = isLocal ? 'http://localhost:3000' : prodURL;
		return mainUrl;
	} catch (error) {}
	return null;
};

const parseJwt = (token: any) => {
	try {
		const base64Url = token.split('.')[1];
		const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
		return JSON.parse(jsonPayload);
	} catch (error) {}
	return null;
};

const callApi = async (url: any, method?: string, body?: any): Promise<Response> => {
	const cookies = new Cookies();
	const access_token = cookies.get('access_token');
	const options: any = {
		method: method || 'GET',
		headers: {
			Authorization: 'Bearer ' + access_token,
			'Content-Type': 'application/json',
		},
	};
	if (options.method === 'POST' || options.method === 'PUT') {
		options['body'] = body ? JSON.stringify(body) : '';
	}
	try {
		const response = await fetch(getApiUrl(url), options);
		if (response.status === 403 || response.status === 401) {
			// const retryResponse = await retryCallApi(url, options);
			// return retryResponse;
		}
		return response;
	} catch (error: any) {
		if (error?.response?.status === 403 || error?.response?.status === 401) {
			// const retryResponse = await retryCallApi(url, options);
			// return retryResponse;
		}
		return Promise.reject(error);
	}
};

const getCmsURL = async () => {
	if (cmsUrl) {
		return cmsUrl;
	}
	try {
		const response = await callApi(`auth/getcmsurl`);
		if (response.status === 200) {
			const data = await response.json();
			cmsUrl = data.contentUrl;
			return data.contentUrl;
		} else {
			console.error('Failed to get cms url');
		}
	} catch (error) {
		console.error('Failed to get cms url');
	}
	return null;
};

const getCurrentUser = () => {
	try {
		const cookies = new Cookies();
		const access_token = cookies.get('access_token');
		if (access_token) {
			const token = parseJwt(access_token);
			if (token.id) {
				const user = token.id;
				return user || null;
			}
		} else {
			// redirectLogin();
		}
	} catch (error) {
		console.error('Error:', error);
	}
	return null;
};

const getUserById = async (userId: string) => {
	try {
		const response = await callApi(`users/${userId}`);
		if (response.status === 200) {
			const user = await response.json();
			return user || null;
		} else {
			console.error('Failed to get user data');
		}
	} catch (error) {
		console.error('Error:', error);
	}
	return null;
};

const getUserDataById = async (userId: string) => {
	try {
		const response = await callApi(`registration/${userId}`);
		if (response.status === 200) {
			const data = await response.json();
			return data || null;
		} else {
			console.error('Failed to get registration data');
		}
	} catch (error) {
		console.error('Error:', error);
	}
	return null;
};

const getUserData = async () => {
	try {
		const user = await getCurrentUser();
		if (user && user.id) {
			const userData = await getUserDataById(user.id);
			return userData || null;
		}
	} catch (error) {
		console.log(error);
	}
	return null;
};

const getAllUser = async () => {
	try {
		const response = await callApi(`users`);
		if (response.status === 200) {
			const data = await response.json();
			return data || null;
		} else {
			console.error('Failed to get registration data');
		}
	} catch (error) {
		console.error('Error getting user data', error);
	}

	return null;
};

const getAllRegisterUser = async () => {
	try {
		const response = await callApi(`registration`);
		if (response.status === 200) {
			const data = await response.json();
			return data || null;
		} else {
			console.error('Failed to get registration data');
		}
	} catch (error) {
		console.error('Error getting user data', error);
	}

	return null;
};

const getUserDetailsData = async (userId: string) => {
	try {
		const response = await callApi(`studentdetails/${userId}`);
		if (response.status === 200) {
			const data = await response.json();
			return data || null;
		} else {
			console.error('Failed to get studentdetails data');
		}
	} catch (error) {
		console.error('Error getting user data', error);
	}

	return null;
};

const checkAdminUser = async (email: any) => {
	try {
		const contentAPI = await getCmsURL();
		if (contentAPI) {
			const filter = `filters[email][$eqi]=${email}`;
			const url = `${contentAPI}/api/administrators?${filter}`;
			const options: any = {
				method: 'GET',
			};
			const response = await fetch(url, options);
			if (response.status === 200) {
				const data = await response.json();
				return data?.data?.length > 0 || false;
			}
		}
	} catch (error) {
		console.error(error);
	}
	return false;
};

const getStrapiData = async (path: string | any): Promise<any> => {
	try {
		const contentAPI = await getCmsURL();
		if (contentAPI) {
			const url = `${contentAPI}/api/${path}`;
			const options: any = {
				method: 'GET',
			};
			const response = await fetch(url, options);
			if (response.status === 200) {
				const data = await response.json();
				return data?.data || null;
			} else {
				console.error('Failed to get cms data');
			}
		} else {
			console.error('Failed to get cms url');
		}
	} catch (error) {
		console.error('Failed to get cms data');
	}
};

const getRegistrationUrl = async (url?: string | null) => {
	try {
		const currentUrl = url ? `${window.location.protocol}//${window.location.host}/${url}` : window.location.href;
		const redirectURI = await getMainUrl();
		const redirect_url = `${redirectURI}/registration?redirect_url=${encodeURIComponent(currentUrl)}`;
		return redirect_url;
	} catch (error) {}
	return null;
};

const getEnrollUrl = async () => {
	try {
		const redirectURI = await getMainUrl();
		const redirect_url = `${redirectURI}/enroll?from=dashboard`;
		return redirect_url;
	} catch (error) {}
	return null;
};

const updateUserDetail = async (userId: string, body: any) => {
	try {
		await callApi(`registration/${userId}`, 'PUT', body);
		return true;
	} catch (error) {
		console.error('Error while updatting the user data', error);
		return false;
	}
};

const updateCourseDetails = async (courseId: string | null, method: string | null) => {
	try {
		const filter = `filters[id][$eq]=${courseId}`;
		const data = await getStrapiData(`courses?${filter}&populate=*`);
		if (data.length > 0) {
			if (method === 'Add' && data[0]?.attributes?.isFull) {
			} else {
				const availableSeat = Number(data[0]?.attributes?.availableSeat) || null;
				let aSeat = 0;
				if (availableSeat && availableSeat > 0) {
					aSeat = method === 'Add' ? availableSeat - 1 : method === 'Delete' ? availableSeat + 1 : availableSeat;
				}
				const isFull = aSeat === 0;
				const contentAPI = await getCmsURL();
				if (contentAPI) {
					const apiUrl = `${contentAPI}/api/courses/${courseId}`;
					const body = {
						data: {
							availableSeat: aSeat.toString(),
							isFull: isFull,
						},
					};
					const options: any = {
						method: 'PUT',
						headers: { 'Content-Type': 'application/json' },
						body: body ? JSON.stringify(body) : '',
					};
					const response = await fetch(apiUrl, options);
					if (response.status === 200) {
						return true;
					} else {
						throw new Error(`API request failed with status: ${response.status}`);
					}
				}
			}
		}
	} catch (error) {
		console.error('Error:', error);
		return false;
	}
};

const getMarkedImage = async (data: string) => {
	const contentAPI = await getCmsURL();
	if (contentAPI) {
		const imageRegex = /!\[([^\]]*?)\]\(([^)]*?)\)/g;
		const newData = data?.replace(imageRegex, (match, altText, imageUrl) => {
			return `<img style='height: 90%; width: 90%;' alt='${altText}' src='${contentAPI}${imageUrl}' />`;
		});
		return newData;
	} else return data;
};

const isProduction = () => {
	try {
		const devDomain = ['mvpdev', 'localhost'];
		let domain: string = window.location.hostname;
		const urlParts = domain.split('.');
		domain = domain === 'localhost' ? urlParts[0] : urlParts[1];
		return !devDomain.includes(domain.toLocaleLowerCase());
	} catch (error) {}
	return false;
};

init();

export {
	getMainUrl,
	getCmsURL,
	getCurrentUser,
	getUserData,
	checkAdminUser,
	getAllUser,
	getAllRegisterUser,
	getStrapiData,
	getUserById,
	getUserDataById,
	getRegistrationUrl,
	getEnrollUrl,
	updateUserDetail,
	updateCourseDetails,
	getMarkedImage,
	isProduction,
	getDomain,
	getDashboardDomain,
	getUserDetailsData,
	callApi,
	getHostName,
};
