import { getHostName } from '../shared/common';

const getApiUrl = (url: string) => {
	const loc = window.location;
	const mode: string = loc.hostname === 'localhost' ? 'development' : 'production';
	let prodURL = `api.${getHostName()}`;
	if (mode === 'production') {
		const hostList = prodURL?.split('.');
		const hostListNew = hostList.filter((item) => item !== 'dashboard');
		prodURL = hostListNew.join('.');
	}
	let apiUrl = mode === 'development' ? 'localhost:3001/api' : prodURL;
	const auth = ['auth', 'users', 'register'];
	const register = ['registration', 'payment', 'studentdetails'];
	if (auth.some((v: any) => url.includes(v))) {
		apiUrl = mode === 'development' ? 'localhost:3001/api' : `${prodURL}/authentication/api`;
	} else if (register.some((v: any) => url.includes(v))) {
		apiUrl = mode === 'development' ? 'localhost:3002/api' : `${prodURL}/registration/api`;
	}
	return `${loc.protocol}//${apiUrl}/${url}`;
};

export { getApiUrl };
