"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const icons_material_1 = require("@mui/icons-material");
const better_react_mathjax_1 = require("better-react-mathjax");
const shared_1 = require("../shared");
const CollegeLink = () => {
    const [collegeList, setCollegeList] = (0, react_1.useState)([]);
    const marked = require('markdown-it')({
        html: true,
        linkify: true,
        typographer: true,
    });
    (0, react_1.useEffect)(() => {
        GetResoureData();
    }, []);
    const GetResoureData = async () => {
        try {
            const data = await (0, shared_1.getStrapiData)('college-links?sort=displayOrder:asc');
            if (data.length > 0) {
                setCollegeList(data || []);
            }
        }
        catch (error) { }
    };
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: 'home-page-root' }, { children: [(0, jsx_runtime_1.jsx)("img", { src: '/assets/background_1.png', alt: 'home-screen', className: 'bg_1_image', loading: 'lazy' }), (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: 'column', spacing: 2, justifyContent: 'space-between', sx: { width: '100%', zIndex: 1 } }, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ className: 'college_link_header' }, { children: "College Info Links" })), (0, jsx_runtime_1.jsx)(material_1.Stack, Object.assign({ direction: 'column', className: 'college_link_bottom', spacing: 1 }, { children: collegeList.map((item, index) => {
                            var _a;
                            return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'college_link_link', rel: 'noreferrer' }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ style: { display: 'flex', flexDirection: 'row', alignItems: 'center' } }, { children: [(0, jsx_runtime_1.jsx)(icons_material_1.Language, { className: 'college_link_icon' }), "\u00A0", (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(better_react_mathjax_1.MathJax, Object.assign({ inline: true, dynamic: true }, { children: (0, jsx_runtime_1.jsx)("span", { className: 'college_link_text', dangerouslySetInnerHTML: { __html: marked.render((_a = item === null || item === void 0 ? void 0 : item.attributes) === null || _a === void 0 ? void 0 : _a.detail) || '' } }) })) })] })) }), index));
                        }) }))] }))] })));
};
exports.default = CollegeLink;
