"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const react_router_dom_1 = require("react-router-dom");
const diagnosticApi_1 = require("../services/diagnosticApi");
const shared_1 = require("../shared");
const ReportCard = ({ user, testType, quiz, data }) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const onViewReportButtonClick = (0, react_1.useCallback)(async (userId, quizId) => {
        navigate(`/user-report?userId=${userId}&quizId=${quizId}`);
    }, []);
    const [report, setReport] = (0, react_1.useState)({});
    const { data: userReport, isLoading } = (0, diagnosticApi_1.useQuizDetailQuery)(data === null || data === void 0 ? void 0 : data.quizId);
    const [diagData, setDiagData] = (0, react_1.useState)([]);
    const initData = async () => {
        setDiagData(await (0, shared_1.getStrapiData)(`diagnostics?&populate=*&pagination[pageSize]=100`));
    };
    (0, react_1.useEffect)(() => {
        if (userReport) {
            initData();
            setReport(userReport);
        }
    }, [userReport]);
    const getTestType = (id) => {
        var _a;
        const test = testType === null || testType === void 0 ? void 0 : testType.find((x) => x.id === `${id}`);
        return ((_a = test === null || test === void 0 ? void 0 : test.attributes) === null || _a === void 0 ? void 0 : _a.Name) || 'N/A';
    };
    const getQuiz = (id) => {
        return id || '';
    };
    const getCategory = (data) => {
        var _a;
        if (data && (data === null || data === void 0 ? void 0 : data.score)) {
            const diagId = Object.keys(data === null || data === void 0 ? void 0 : data.score)[0];
            const diagDetails = diagData === null || diagData === void 0 ? void 0 : diagData.find((x) => `${x.id}` === `${diagId}`);
            return ((_a = diagDetails === null || diagDetails === void 0 ? void 0 : diagDetails.attributes) === null || _a === void 0 ? void 0 : _a.Category) || '';
        }
        else {
            return '';
        }
    };
    const getDiagArea = (reportDetails, id) => {
        let diagAreaObj = {}, scoreobj = reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score, overallScoreobj = reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.overallScore, resultScoreObj = {}, resultOverallScoreObj = {}, resultScore = 0, resultOverallScore = 0;
        if (reportDetails && (reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score) && (reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score) && (reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.overallScore)) {
            Object.keys(reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score).forEach((key, idx) => {
                var _a, _b, _c, _d;
                const diagDetails = diagData === null || diagData === void 0 ? void 0 : diagData.find((x) => `${x.id}` === `${key}`);
                diagAreaObj[key] = (_d = (_c = (_b = (_a = diagDetails === null || diagDetails === void 0 ? void 0 : diagDetails.attributes) === null || _a === void 0 ? void 0 : _a.DiagnosticsArea) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.Name;
            });
            Object.keys(diagAreaObj).forEach((key, idx) => {
                if (scoreobj[key] != undefined) {
                    if (resultScoreObj[diagAreaObj[key]]) {
                        resultScoreObj[diagAreaObj[key]] = Number(resultScoreObj[diagAreaObj[key]]) + Number(scoreobj[key]);
                    }
                    else {
                        resultScoreObj[diagAreaObj[key]] = Number(scoreobj[key]);
                    }
                }
                if (overallScoreobj[key] != undefined) {
                    if (resultOverallScoreObj[diagAreaObj[key]]) {
                        resultOverallScoreObj[diagAreaObj[key]] = Number(resultOverallScoreObj[diagAreaObj[key]]) + Number(overallScoreobj[key]);
                    }
                    else {
                        resultOverallScoreObj[diagAreaObj[key]] = Number(overallScoreobj[key]);
                    }
                }
            });
            Object.keys(resultScoreObj).forEach((key, idx) => {
                if (id == key) {
                    resultScore += Number(resultScoreObj[key]);
                    resultOverallScore += Number(resultOverallScoreObj[key]);
                }
            });
            if (resultScore || resultOverallScore)
                return resultScore.toString() + '/' + resultOverallScore.toString() + ' (' + Math.round(((resultScore / resultOverallScore) * 100)) + '%)';
            else
                return "0/0 (0%)";
        }
        else
            return "0/0 (0%)";
    };
    const getDiag = (reportDetails) => {
        var _a;
        if (reportDetails && (reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score)) {
            const diagId = Object.keys(reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score)[0];
            const diagDetails = diagData === null || diagData === void 0 ? void 0 : diagData.find((x) => `${x.id}` === `${diagId}`);
            return ((_a = diagDetails === null || diagDetails === void 0 ? void 0 : diagDetails.attributes) === null || _a === void 0 ? void 0 : _a.Name) || '';
        }
        else {
            return '';
        }
    };
    const getOverallDiag = (reportDetails) => {
        let overallScoreNum = 0, overallScoreDem = 0;
        if (reportDetails && (reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score))
            overallScoreNum = Number(Object.values(reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score)[0]);
        if (reportDetails && (reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.overallScore))
            overallScoreDem = Number(Object.values(reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.overallScore)[0]);
        if (overallScoreNum || overallScoreDem)
            return overallScoreNum.toString() + " / " + overallScoreDem.toString();
        else
            return "0 / 0";
    };
    const getOverallDiagPercent = (reportDetails) => {
        let overallScoreNum = 0, overallScoreDem = 0;
        if (reportDetails && (reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score))
            overallScoreNum = Number(Object.values(reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.score)[0]);
        if (reportDetails && (reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.overallScore))
            overallScoreDem = Number(Object.values(reportDetails === null || reportDetails === void 0 ? void 0 : reportDetails.overallScore)[0]);
        if (overallScoreNum && overallScoreDem)
            return (Math.round((overallScoreNum / overallScoreDem) * 100)).toString() + '%';
        else
            return "0%";
    };
    const getScores = (id) => {
        let scoreRange = userReport === null || userReport === void 0 ? void 0 : userReport.scoreRange;
        if (id && scoreRange)
            return scoreRange[id];
        else
            return "0 - 0";
    };
    const getOverallScores = () => {
        let overallScoreNum = 0, overallScoreDem = 0;
        if (userReport) {
            let score = userReport === null || userReport === void 0 ? void 0 : userReport.scoreRange;
            for (var key in score) {
                let resArray = score[key].split(" - ");
                overallScoreNum += Number(resArray[0]);
                overallScoreDem += Number(resArray[1]);
            }
            if ((userReport === null || userReport === void 0 ? void 0 : userReport.type) === 0) {
                if (userReport === null || userReport === void 0 ? void 0 : userReport.score)
                    overallScoreNum = Number(Object.values(userReport === null || userReport === void 0 ? void 0 : userReport.score)[0]);
                if (userReport === null || userReport === void 0 ? void 0 : userReport.overallScore)
                    overallScoreDem = Number(Object.values(userReport === null || userReport === void 0 ? void 0 : userReport.overallScore)[0]);
            }
        }
        if (overallScoreNum || overallScoreDem)
            return overallScoreNum.toString() + " - " + overallScoreDem.toString();
        else
            return "0 - 0";
    };
    const formatDate = (date) => {
        try {
            let currenDate = new Date(date);
            if (!date)
                currenDate = new Date();
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };
            const formattedDate = currenDate.toLocaleDateString(undefined, options);
            return formattedDate;
        }
        catch (error) { }
        return '';
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Card, Object.assign({ variant: 'elevation', className: 'report_cards_container' }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_date' }, { children: formatDate(data === null || data === void 0 ? void 0 : data.completeDate) })), (() => {
                if ((report === null || report === void 0 ? void 0 : report.type) != 0) {
                    return ((0, jsx_runtime_1.jsx)(material_1.Card, Object.assign({ variant: 'elevation', className: 'report_cards' }, { children: (0, jsx_runtime_1.jsxs)(material_1.Grid, Object.assign({ style: { padding: '1vmax' }, container: true, direction: 'column', justifyContent: 'space-between' }, { children: [(0, jsx_runtime_1.jsx)(material_1.Grid, Object.assign({ item: true, xs: true }, { children: (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: 'column', spacing: 2, alignItems: 'center', justifyContent: 'center' }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_header' }, { children: getCategory(data) })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: 'report_cards_score' }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_txt' }, { children: "Your Score Range" })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_score_total' }, { children: getOverallScores() }))] }))] })) })), (0, jsx_runtime_1.jsx)(material_1.Divider, {}), (0, jsx_runtime_1.jsx)(material_1.Grid, Object.assign({ item: true, xs: true }, { children: (0, jsx_runtime_1.jsxs)(material_1.Grid, Object.assign({ container: true, direction: 'column', spacing: 1, className: 'report_cards_module' }, { children: [(0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("div", Object.assign({ style: { fontSize: '1.15vmax' } }, { children: "Section Scores" })), Object.keys((report === null || report === void 0 ? void 0 : report.scoreRange) || {}).map((x, idx) => {
                                                return ((0, jsx_runtime_1.jsxs)(material_1.Grid, Object.assign({ item: true, xs: true, style: { width: '100%' } }, { children: [(0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: 'row', justifyContent: 'space-between', alignItems: 'center' }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_module_txt' }, { children: getQuiz(x) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_module_score' }, { children: getScores(x) }))] })), (0, jsx_runtime_1.jsx)(material_1.Stack, Object.assign({ direction: 'row', justifyContent: 'flex-end', alignItems: 'center' }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_module_percent' }, { children: getDiagArea(report, x) })) }))] }), idx));
                                            })] })) })), (0, jsx_runtime_1.jsx)(material_1.Grid, Object.assign({ item: true, xs: true }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'loginbutton', onClick: () => onViewReportButtonClick(user === null || user === void 0 ? void 0 : user.id, data === null || data === void 0 ? void 0 : data.quizId) }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'take-diagnostics-wrapper' }, { children: (0, jsx_runtime_1.jsx)("b", Object.assign({ className: 'take-diagnostics-button-text' }, { children: "Score Details" })) })) })) }))] })) })));
                }
                else if ((report === null || report === void 0 ? void 0 : report.type) == 0) {
                    return ((0, jsx_runtime_1.jsx)(material_1.Card, Object.assign({ variant: 'elevation', className: 'report_cards' }, { children: (0, jsx_runtime_1.jsxs)(material_1.Grid, Object.assign({ style: { padding: '1vmax' }, container: true, direction: 'column', justifyContent: 'space-between' }, { children: [(0, jsx_runtime_1.jsx)(material_1.Grid, Object.assign({ item: true, style: { height: '22.5vmax' } }, { children: (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: 'column', spacing: 2, alignItems: 'center', justifyContent: 'center' }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_header' }, { children: getCategory(data) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_txt' }, { children: getDiag(data) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_score_total' }, { children: getOverallDiagPercent(data) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'report_cards_module_score' }, { children: getOverallDiag(data) }))] })) })), (0, jsx_runtime_1.jsx)(material_1.Grid, Object.assign({ item: true, xs: true }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'loginbutton', onClick: () => onViewReportButtonClick(user === null || user === void 0 ? void 0 : user.id, data === null || data === void 0 ? void 0 : data.quizId) }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'take-diagnostics-wrapper' }, { children: (0, jsx_runtime_1.jsx)("b", Object.assign({ className: 'take-diagnostics-button-text' }, { children: "Score Details" })) })) })) }))] })) })));
                }
            })()] })));
};
exports.default = ReportCard;
