"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTestTypeByIdQuery = exports.useTestTypeQuery = void 0;
const redux_state_1 = require("@alpha-prep/redux-state");
const shared_1 = require("../shared");
const baseApiNew = (0, shared_1.isProduction)() ? redux_state_1.baseApiProd : redux_state_1.baseApi;
const testTypeApi = baseApiNew.injectEndpoints({
    endpoints: (build) => ({
        testType: build.query({
            query: () => '/cms/common/test-type',
        }),
        testTypeById: build.query({
            query: (id) => `/cms/common/diagnostics/test-type/${id}`,
        }),
    }),
    overrideExisting: false,
});
exports.useTestTypeQuery = testTypeApi.useTestTypeQuery, exports.useTestTypeByIdQuery = testTypeApi.useTestTypeByIdQuery;
