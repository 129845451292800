"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApiUrl = void 0;
const shared_1 = require("../shared");
const getApiUrl = (url) => {
    const loc = window.location;
    const mode = loc.hostname === 'localhost' ? 'development' : 'production';
    let prodURL = `api.${(0, shared_1.getHostName)()}`;
    if (mode === 'production') {
        const hostList = prodURL === null || prodURL === void 0 ? void 0 : prodURL.split('.');
        const hostListNew = hostList.filter((item) => item !== 'dashboard');
        prodURL = hostListNew.join('.');
    }
    let apiUrl = mode === 'development' ? 'localhost:3001/api' : prodURL;
    const auth = ['auth', 'users', 'register'];
    const register = ['registration', 'payment', 'studentdetails'];
    if (auth.some((v) => url.includes(v))) {
        apiUrl = mode === 'development' ? 'localhost:3001/api' : `${prodURL}/authentication/api`;
    }
    else if (register.some((v) => url.includes(v))) {
        apiUrl = mode === 'development' ? 'localhost:3002/api' : `${prodURL}/registration/api`;
    }
    return `${loc.protocol}//${apiUrl}/${url}`;
};
exports.getApiUrl = getApiUrl;
