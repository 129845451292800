"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOverAllScore = exports.getScore = void 0;
const getScore = (questions, answers, questionDetails) => {
    var _a, _b;
    const count = ((_a = questions === null || questions === void 0 ? void 0 : questions.questions) === null || _a === void 0 ? void 0 : _a.length) || 0;
    const correctCount = ((_b = questions === null || questions === void 0 ? void 0 : questions.questions) === null || _b === void 0 ? void 0 : _b.filter(x => {
        var _a, _b, _c, _d;
        const ans = answers === null || answers === void 0 ? void 0 : answers.find(a => a.questionId === x);
        const quest = questionDetails[x];
        if ((quest === null || quest === void 0 ? void 0 : quest.QuestionType) === 'choice') {
            const index = (_a = quest === null || quest === void 0 ? void 0 : quest.AnswerChoices) === null || _a === void 0 ? void 0 : _a.findIndex(ac => ac.IsCorrect);
            return (ans === null || ans === void 0 ? void 0 : ans.selectedChoice) === index;
        }
        else if ((quest === null || quest === void 0 ? void 0 : quest.QuestionType) === 'number' || (quest === null || quest === void 0 ? void 0 : quest.QuestionType) === 'text') {
            return (_b = quest === null || quest === void 0 ? void 0 : quest.CorrectAnswer) === null || _b === void 0 ? void 0 : _b.some(i => { var _a; return i.Answer.toLowerCase() === ((_a = ans === null || ans === void 0 ? void 0 : ans.answer) === null || _a === void 0 ? void 0 : _a.toLowerCase()); });
        }
        return ((_c = ans === null || ans === void 0 ? void 0 : ans.answer) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === ((_d = quest === null || quest === void 0 ? void 0 : quest.CorrectAnswer) === null || _d === void 0 ? void 0 : _d.toLowerCase());
    }).length) || 0;
    return { percent: count > 0 ? Math.round((correctCount / count) * 100) : 0, count, correctCount };
};
exports.getScore = getScore;
const getOverAllScore = (diagnostic, questionDetails) => {
    const { questions, answers } = diagnostic || {};
    const allQuestions = Object.keys(questions || {}).map(x => { var _a; return ((_a = questions[x]) === null || _a === void 0 ? void 0 : _a.questions) || []; }).flat();
    const count = allQuestions.length;
    const correctCount = allQuestions.filter(x => {
        var _a, _b, _c, _d;
        const ans = answers === null || answers === void 0 ? void 0 : answers.find(a => a.questionId === x);
        const quest = questionDetails[x];
        if ((quest === null || quest === void 0 ? void 0 : quest.QuestionType) === 'choice') {
            const index = (_a = quest === null || quest === void 0 ? void 0 : quest.AnswerChoices) === null || _a === void 0 ? void 0 : _a.findIndex(ac => ac.IsCorrect);
            return (ans === null || ans === void 0 ? void 0 : ans.selectedChoice) === index;
        }
        else if ((quest === null || quest === void 0 ? void 0 : quest.QuestionType) === 'number' || (quest === null || quest === void 0 ? void 0 : quest.QuestionType) === 'text') {
            return (_b = quest === null || quest === void 0 ? void 0 : quest.CorrectAnswer) === null || _b === void 0 ? void 0 : _b.some(i => { var _a; return i.Answer.toLowerCase() === ((_a = ans === null || ans === void 0 ? void 0 : ans.answer) === null || _a === void 0 ? void 0 : _a.toLowerCase()); });
        }
        return ((_c = ans === null || ans === void 0 ? void 0 : ans.answer) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === ((_d = quest === null || quest === void 0 ? void 0 : quest.CorrectAnswer) === null || _d === void 0 ? void 0 : _d.toLowerCase());
    }).length;
    return { percent: count > 0 ? Math.round((correctCount / count) * 100) : 0, count, correctCount };
};
exports.getOverAllScore = getOverAllScore;
