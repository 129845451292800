"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserReportQuery = exports.useUserReportListQuery = void 0;
const redux_state_1 = require("@alpha-prep/redux-state");
const shared_1 = require("../shared");
const baseApiNew = (0, shared_1.isProduction)() ? redux_state_1.baseApiProd : redux_state_1.baseApi;
const testTypeApi = baseApiNew.injectEndpoints({
    endpoints: (build) => ({
        userReportList: build.query({
            query: (userId) => ({
                url: `/diagnostic/results/${userId}`,
                params: {
                    cacheBusting: new Date().toISOString()
                }
            }),
        }),
        userReport: build.query({
            query: (quizId) => ({
                url: `/diagnostic/result/${quizId}`,
                params: {
                    cacheBusting: new Date().toISOString()
                }
            }),
        }),
    }),
    overrideExisting: false,
});
exports.useUserReportListQuery = testTypeApi.useUserReportListQuery, exports.useUserReportQuery = testTypeApi.useUserReportQuery;
