import { useEffect } from 'react';
import {  useState } from 'react';
import './App.scss';
import { Outlet, Navigate } from 'react-router-dom';
import { getCurrentUser, callApi, getMainUrl } from '../shared/common';

function App() {
	const [isAuth, setIsAuth] = useState("");

	const handleProfile = async () => {
		let redirect_url = await getMainUrl();
		redirect_url = redirect_url + `/registration?to=dashboard`;
		window.location.replace(redirect_url);		
	};

	useEffect(() => {					
		const user = getCurrentUser();
		checkUserInfo(user)
	});
	const checkUserInfo = (user: any) => {		
		try {
			callApi(`registration/${user}`, 'GET', {})
			.then((result: any) => {
				if(result.status !== 404 && result.status !== 403 && result.status !== 400)
					setIsAuth("true");
				else{
					handleProfile();
					setIsAuth("false");
				}
			}).catch(()=>{
				handleProfile();
				setIsAuth("false");
			})
		}
		catch (error) {			
			console.error('Error fetching user data', error);
			handleProfile();
			setIsAuth("false");
		}
	};
	// const onTextClick = useCallback(() => {
	//   // Please sync "edit Profile" to the project
	// }, []);

	// const onAlphaPrepLogoContainerClick = useCallback(() => {
	//   // Please sync "Landing Page" to the project
	// }, []);

	// const onHelpAICustomizeClick = useCallback(() => {
	//   // Please sync "Dashboard_2" to the project
	// }, []);
	//const redirect_url : string | any = handleProfile();
	//const user = getCurrentUser();
	//const userData : boolean | any = checkUserInfo(user);
	if (isAuth === "") return null;
	return isAuth === "true" ? <Outlet /> : <Navigate to={'/'} replace={true} /> ;
}

export default App;
