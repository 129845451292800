import React from 'react';
import { hydrate } from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { DASHBOARD_ROUTE } from '@alpha-prep/dashboard';
import { DIAGNOSTIC_ROUTE } from '@alpha-prep/dashboard-diagnostic';
import { Provider } from 'react-redux';
import { store } from '@alpha-prep/redux-state';
import { MathJaxContext } from 'better-react-mathjax';

// declare global {
//   interface Window {
//       MathJax: any;
//   }
// }

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '',
				children: [...DASHBOARD_ROUTE],
			},
			{
				path: 'diagnostics',
				children: [...DIAGNOSTIC_ROUTE],
			},
		],
	},
]);

const AppElement = () => {
	// useEffect(() => {
	//   if (typeof window?.MathJax !== "undefined") {
	//     window.MathJax.typeset()
	//   }
	// }, [])
	const config = {
		loader: { load: ['input/asciimath'] },
	};
	return (
		<React.StrictMode>
			<MathJaxContext config={config}>
				<Provider store={store}>
					<RouterProvider router={router} />
				</Provider>
			</MathJaxContext>
		</React.StrictMode>
	);
};

const rootElement = document.getElementById('root');
if (rootElement && rootElement.hasChildNodes()) {
	hydrate(<AppElement />, rootElement);
} else {
	const root = createRoot(rootElement!); // createRoot(container!) if you use TypeScript
	root.render(<AppElement />);
	// render(<AppElement />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
