"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const material_1 = require("@mui/material");
const styles_1 = require("@mui/material/styles");
const Tabs_1 = __importStar(require("@mui/material/Tabs"));
const shared_1 = require("../shared");
const StyledTab = (0, styles_1.styled)(material_1.Tab)({
    '&.MuiTab-root': {
        textDecoration: 'none',
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontSize: '1vmax',
    },
    '&.Mui-selected': {
        color: '#FFF',
        background: 'linear-gradient(180deg, #0146c7, #052d77)',
        fontFamily: 'Poppins',
        fontSize: '1.5vmax',
        fontWeight: '400',
    },
});
const TabPanel = (props) => {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ role: 'tabpanel', hidden: value !== index, id: `full-width-tabpanel-${index}`, "aria-labelledby": `full-width-tab-${index}` }, other, { children: value === index && (0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: { p: 1 } }, { children: children })) })));
};
const tabProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};
const CheckList = () => {
    const { userId } = (0, react_router_dom_1.useParams)();
    const theme = (0, styles_1.useTheme)();
    const [registerData, setRegisterData] = (0, react_1.useState)(null);
    const [contentApi, setContentApi] = react_1.default.useState(null);
    const [gradeList, setGradeList] = react_1.default.useState([]);
    const [checklist, setChecklist] = react_1.default.useState([]);
    const [tabValue, setTabValue] = react_1.default.useState(0);
    const [checkedData, setCheckedData] = react_1.default.useState([]);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        getChecklist(newValue);
    };
    const handleCheckChange = (event) => {
        const gradeData = (checkedData || []).filter((item) => (item === null || item === void 0 ? void 0 : item.grade) === tabValue);
        const value = parseInt(event.target.name);
        const newCheckedData = checkedData;
        if (event.target.checked) {
            if (gradeData.length > 0) {
                newCheckedData.map((item) => {
                    if ((item === null || item === void 0 ? void 0 : item.grade) === tabValue) {
                        if (!(item === null || item === void 0 ? void 0 : item.checked.includes(value))) {
                            item === null || item === void 0 ? void 0 : item.checked.push(value);
                        }
                    }
                });
            }
            else {
                newCheckedData.push({
                    grade: tabValue,
                    checked: [value],
                });
            }
        }
        else {
            if (gradeData.length > 0) {
                newCheckedData.map((item) => {
                    var _a;
                    if ((item === null || item === void 0 ? void 0 : item.grade) === tabValue) {
                        item.checked = (_a = item === null || item === void 0 ? void 0 : item.checked) === null || _a === void 0 ? void 0 : _a.filter((i) => i !== value);
                    }
                });
            }
        }
        setCheckedData(newCheckedData);
        updateChecklist(newCheckedData);
        getChecklist(tabValue);
    };
    const updateChecklist = (data) => {
        const body = {
            userId: userId,
            checklist: data,
        };
        (0, shared_1.callApi)(`studentdetails`, 'POST', body);
    };
    (0, react_1.useEffect)(() => {
        initData();
        getGradeData();
    }, []);
    const initData = async () => {
        var _a, _b;
        try {
            const regData = await (0, shared_1.getUserData)();
            setRegisterData(regData || null);
            const grades = await (0, shared_1.getStrapiData)(`grades?&sort=displayOrder:asc`);
            setGradeList(grades);
            if ((grades === null || grades === void 0 ? void 0 : grades.length) > 0) {
                setTabValue((_a = grades[0]) === null || _a === void 0 ? void 0 : _a.id);
                getChecklist((_b = grades[0]) === null || _b === void 0 ? void 0 : _b.id);
            }
            setContentApi(await (0, shared_1.getCmsURL)());
        }
        catch (error) {
            console.error('Error fetching user data', error);
        }
    };
    const getGradeData = async () => {
        const userData = await (0, shared_1.getUserDetailsData)(userId);
        setCheckedData((userData === null || userData === void 0 ? void 0 : userData.checklist) || []);
    };
    const getChecklist = async (id) => {
        const checklist = await (0, shared_1.getStrapiData)(`grade-checklists?filters[Grade][id][$eqi]=${id}&sort=displayOrder:asc`);
        setChecklist(checklist);
    };
    const isChecked = (id) => {
        let isCheck = false;
        (checkedData || []).forEach((item) => {
            var _a;
            if ((item === null || item === void 0 ? void 0 : item.grade) === tabValue) {
                if ((_a = item === null || item === void 0 ? void 0 : item.checked) === null || _a === void 0 ? void 0 : _a.includes(id)) {
                    isCheck = true;
                }
            }
        });
        return isCheck;
    };
    const renderImage = (data) => {
        const imageRegex = /!\[([^\]]*?)\]\(([^)]*?)\)/g;
        const newData = data === null || data === void 0 ? void 0 : data.replace(imageRegex, (match, altText, imageUrl) => {
            return `<img style='height: 90%; width: 90%;' alt='${altText}' src='${contentApi}${imageUrl}' />`;
        });
        return newData;
    };
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: 'home-page-root' }, { children: [(0, jsx_runtime_1.jsx)("img", { src: '/assets/background_3.png', alt: 'home-screen', className: 'bg_1_image', loading: 'lazy' }), (0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: { width: '100%', zIndex: 1 } }, { children: (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: 'column', spacing: 2 }, { children: [(0, jsx_runtime_1.jsx)(material_1.AppBar, Object.assign({ position: 'static' }, { children: (0, jsx_runtime_1.jsx)(Tabs_1.default, Object.assign({ value: tabValue, onChange: handleTabChange, textColor: 'inherit', "aria-label": 'full width tabs example', TabIndicatorProps: {
                                    style: {
                                        backgroundColor: '#FFF',
                                    },
                                }, variant: 'scrollable', scrollButtons: true, sx: {
                                    [`& .${Tabs_1.tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                    color: '#000',
                                    backgroundColor: '#FFF',
                                    fontFamily: 'Poppins',
                                    fontSize: '1.5vmax',
                                    fontWeight: '400',
                                } }, { children: (gradeList || []).map((tab, idx) => {
                                    var _a;
                                    return ((0, jsx_runtime_1.jsx)(StyledTab, Object.assign({ value: tab.id, label: (_a = tab === null || tab === void 0 ? void 0 : tab.attributes) === null || _a === void 0 ? void 0 : _a.Name, sx: { minWidth: 'fit-content', flex: 1 } }, tabProps(tab.id)), idx));
                                }) })) })), (0, jsx_runtime_1.jsx)(material_1.Typography, {}), (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: 'column', spacing: 0 }, { children: [(0, jsx_runtime_1.jsxs)(material_1.Typography, Object.assign({ className: 'checklist-sub-header' }, { children: ["These are ", (0, jsx_runtime_1.jsx)("b", { children: "Some" }), " of Your Key \u2018To Do\u2019s\u2019"] })), (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ className: 'checklist-sub-header' }, { children: "Check When Completed" }))] })), (gradeList || []).map((tab, idx) => ((0, jsx_runtime_1.jsx)(TabPanel, Object.assign({ value: tabValue, index: tab.id, dir: theme.direction }, { children: (0, jsx_runtime_1.jsx)(material_1.FormGroup, Object.assign({ className: 'checklist-group' }, { children: (checklist || []).map((tab, cidx) => {
                                    var _a, _b;
                                    return ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(material_1.Checkbox, { size: 'small', className: 'checklist-checkbox' }), className: 'checklist-item', name: (_a = tab.id) === null || _a === void 0 ? void 0 : _a.toString(), checked: isChecked(tab.id), onChange: handleCheckChange, label: (0, jsx_runtime_1.jsx)("span", { className: 'checklist-text', dangerouslySetInnerHTML: { __html: renderImage((_b = tab === null || tab === void 0 ? void 0 : tab.attributes) === null || _b === void 0 ? void 0 : _b.CheckItem) } }) }, cidx));
                                }) })) }), idx)))] })) }))] })));
};
exports.default = CheckList;
