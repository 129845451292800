"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQuizDetailQuery = exports.useDiagnosticDetailQuery = exports.useDiagnosticsQuery = void 0;
const redux_state_1 = require("@alpha-prep/redux-state");
const shared_1 = require("../shared");
const baseApiNew = (0, shared_1.isProduction)() ? redux_state_1.baseApiProd : redux_state_1.baseApi;
const diagnosticApi = baseApiNew.injectEndpoints({
    endpoints: (builder) => ({
        diagnostics: builder.query({
            query: (id) => `/cms/common/diagnostics/test-type/${id}`,
        }),
        diagnosticDetail: builder.query({
            query: (id) => `/cms/common/diagnostics/${id}`,
        }),
        quizDetail: builder.query({
            query: (quizId) => `/diagnostic/${quizId}`,
        })
    }),
    overrideExisting: false,
});
exports.useDiagnosticsQuery = diagnosticApi.useDiagnosticsQuery, exports.useDiagnosticDetailQuery = diagnosticApi.useDiagnosticDetailQuery, exports.useQuizDetailQuery = diagnosticApi.useQuizDetailQuery;
